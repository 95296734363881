import React, { Component } from "react"
import Select from "react-select"
import { connect } from "react-redux"
import {
  fetchAllStationData,
  localSearchStationsByText,
  localSearchStationsByStations,
  localSearchStationsByIsAlive,
} from "../../actions"
import { fetchIfEmpty } from "../../utils"
import { SelectMultiAutocomplete } from "../../components/common"
import InputField from "../../components/common/InputField"
import "./style.scss"

class StationsFilterer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAliveValue: { label: "הכל", value: null },
    }
  }
  componentDidMount() {
    fetchIfEmpty(
      this.props.allStationsArr,
      this.props.fetchAllStationData(this.props.stations)
    )
  }

  updateActiveStations(items, index) {
    this.props.resetCurrentPage()
    this.props.localSearchStationsByStations(items)
  }

  updateAliveStations(item) {
    this.setState({ isAliveValue: item })
    this.props.resetCurrentPage()
    this.props.localSearchStationsByIsAlive(item)
  }

  render() {
    const { stationsItemSelectList, searchText, filtererActiveStations } =
      this.props

    const isAliveOptions = [
      { label: "פעיל", value: true },
      { label: "לא פעיל", value: false },
      { label: "הכל", value: null },
    ]
    return (
      <div className="stations-filterer">
        <InputField
          show={true}
          extraClass="search-input"
          placeholder="חיפוש מספר/שם/כתובת עמדה"
          value={searchText}
          alt="ניתן לחפש עמדה לפי שם סניף, כתובת או מספר."
          icon="magnifying_glass"
          onChange={(e) => {
            this.props.resetCurrentPage()
            this.props.localSearchStationsByText(e.target.value)
          }}
        />
        <SelectMultiAutocomplete
          placeholder="עמדה"
          items={stationsItemSelectList}
          value={filtererActiveStations}
          onChangeHandler={(itm) => this.updateActiveStations(itm)}
          extraClassName="sations-list"
          isMulti={true}
        />

        <Select
          classNamePrefix="isAlive-select"
          value={this.state.isAliveValue}
          isClearable={true}
          options={isAliveOptions}
          onChange={(e) => this.updateAliveStations(e)}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ station }) => {
  const { stationsLocalSearch, allStationsArr, stationsItemSelectList } =
    station
  const { searchText, filtererActiveStations } = stationsLocalSearch

  return {
    allStationsArr,
    stationsItemSelectList,
    searchText,
    filtererActiveStations,
  }
}

export default connect(mapStateToProps, {
  fetchAllStationData,
  localSearchStationsByText,
  localSearchStationsByStations,
  localSearchStationsByIsAlive,
})(StationsFilterer)

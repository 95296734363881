import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { onUpdateMessage, onGetStations, closeMessage } from "../../../actions"
import { Button } from "../../common/Button"
import { LabeledTextarea as TextArea } from "../../common/LabeledTextarea"
import Message from "../../common/Message"
import SelectStationField from "../../common/SelectField/SelectStationField"
import Title from "../../common/Title"
import "./style.scss"

const UpdateMessageScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    authenticated,
    stationsAll,
    showMessage,
    isError,
    selectedDecathlonStation,
  } = useSelector((state) => ({
    authenticated: state.users.authenticated,
    stationsAll: state.station.stationsAll,
    showMessage: state.station.showMessage,
    isError: state.station.isError,
    selectedDecathlonStation: state.station.selectedDecathlonStation,
  }))

  const [currentStation, setCurrentStation] = useState({})
  const [stationsList, setStationsList] = useState([])
  const [sendSMS, setSendSMS] = useState(true)

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
    }
    dispatch(onGetStations())
  }, [authenticated, dispatch, navigate])

  useEffect(() => {
    if (stationsAll) {
      selectStationForDecathlon()
    }
  }, [stationsAll, selectedDecathlonStation])

  const selectStationForDecathlon = () => {
    if (stationsAll) {
      const result = stationsAll.find(
        (obj) => obj.stationNumber === selectedDecathlonStation
      )
      if (result) {
        setStationsList([result])
        setCurrentStation(result)
        return
      }
    }
    setStationsList(stationsAll || [])
    setCurrentStation(stationsAll?.[0] || {})
  }

  const setValue = (inputName, e) => {
    switch (inputName) {
      case "currentStation":
        setCurrentStation(
          stationsAll.find((el) => el.branchDisplayName === e.target.value)
        )
        break
      case "stationAddress":
        setCurrentStation((prev) => ({
          ...prev,
          branchDisplayAddress: e.target.value,
        }))
        break
      default:
        break
    }
  }

  return (
    <div className="updateMessageScreen">
      <Title text="שינוי כתובת בהודעת סמס ללקוח" />
      <div className="inputs">
        <SelectStationField
          options={stationsList}
          emptyOption={false}
          value={currentStation?.branchDisplayName}
          onChange={(e) => setValue("currentStation", e)}
        />
        <TextArea
          value={currentStation?.branchDisplayAddress}
          inputSize={400}
          onChange={(e) => setValue("stationAddress", e)}
        />
        <div className="checkbox">
          <input
            type="checkbox"
            name="sendSMS"
            id="sendSMS"
            checked={sendSMS}
            onChange={() => setSendSMS(!sendSMS)}
          />
          <label htmlFor="sendSMS">שליחת הודעה לכל החבילות בעמדה</label>
        </div>
        <div className="buttons-wrapper">
          <Button
            onClick={() =>
              dispatch(
                onUpdateMessage(
                  currentStation.branchDisplayAddress,
                  sendSMS,
                  currentStation.stationId
                )
              )
            }
          >
            עדכון
          </Button>

          <Button onClick={() => navigate(-1)}>חזור</Button>
        </div>
        <Message
          show={showMessage}
          isError={isError}
          successText="הכתובת השתנתה בהצלחה"
          errorText="אנא בדוק את הפרטים ונסה שוב"
          onClick={() => {
            dispatch(closeMessage())
            dispatch(onGetStations())
          }}
        />
      </div>
    </div>
  )
}

export default UpdateMessageScreen

import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import Title from "../../common/Title"
import { PaginationNext } from "../../common"
import {
  onStartLoading,
  searchOrders,
  setOrdersSearch,
  setSelectedOrder,
  fetchOrderStatuses,
  fetchOrderTypes,
  fetchStationsByRole,
} from "../../../actions"
import { fetchIfEmpty } from "../../../utils"
import OrdersList from "./OrdersList"
import OrdersSearchFilterer from "../../common/OrdersSearchFilterer"

import "./style.scss"

const OrdersSearchScreen = () => {
  const [searchInterval, setSearchInterval] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    authenticated,
    isLoadingSearch,
    orderList,
    searchData,
    ordersStatusesArr,
    orderTypesArr,
    stationSelectList,
    currentSearchedPage,
    currentSearchedPageStep,
  } = useSelector((state) => ({
    authenticated: state.users.authenticated,
    isLoadingSearch: state.searchOrders.isLoadingSearch,
    orderList: state.searchOrders.orderList,
    searchData: state.searchOrders.searchData,
    ordersStatusesArr: state.orderStatuses.ordersStatusesArr,
    orderTypesArr: state.orderTypes.orderTypesArr,
    stationSelectList: state.station.stationSelectList,
    currentSearchedPage: state.searchOrders.currentSearchedPage,
    currentSearchedPageStep: state.searchOrders.currentSearchedPageStep,
  }))

  const [pageSize, setPageSize] = useState({
    label: 50,
    value: 50,
  })

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
      return
    }

    fetchIfEmpty(stationSelectList, () => dispatch(fetchStationsByRole()))
    fetchIfEmpty(orderTypesArr, () => dispatch(fetchOrderTypes()))
    fetchIfEmpty(ordersStatusesArr, () => dispatch(fetchOrderStatuses()))

    return () => {
      if (searchInterval) {
        clearInterval(searchInterval)
        setSearchInterval(null)
      }
    }
  }, [
    authenticated,
    stationSelectList,
    orderTypesArr,
    ordersStatusesArr,
    searchInterval,
    dispatch,
    navigate,
  ])

  const onClickSetOrderToUpdate = (orderNumber) => {
    const selectedOrder = orderList.find((o) => o.orderNumber === orderNumber)
    if (selectedOrder) {
      dispatch(setSelectedOrder(selectedOrder))
      navigate("/UpdateOrderScreen")
    }
  }

  const onRelocateClick = (orderNumber) => {
    const selectedOrder = orderList.find((o) => o.orderNumber === orderNumber)
    if (selectedOrder) {
      dispatch(setSelectedOrder(selectedOrder))
      navigate("/RelocateOrderScreen")
    }
  }

  const searchOrdersClick = () => {
    dispatch(onStartLoading())
    dispatch(searchOrders(searchData))
  }

  const onSearchFilterChanged = (searchFilters) => {
    dispatch(setOrdersSearch(searchFilters))
  }

  const filterEnterAndSubmit = (e) => {
    if (e.key === "Enter") {
      searchOrdersClick()
    }
  }

  const onPageClick = (page) => {
    dispatch(onStartLoading())
    const updatedSearchData = { ...searchData, page }
    dispatch(setOrdersSearch(updatedSearchData))
    dispatch(searchOrders(updatedSearchData))
  }

  const onPageSizeClick = (e) => {
    const pageStep = Number(e.value)
    setPageSize(e)
    dispatch(onStartLoading())
    const updatedSearchData = { ...searchData, page: 1, pageStep }
    dispatch(setOrdersSearch(updatedSearchData))
    dispatch(searchOrders(updatedSearchData))
  }

  return (
    <div className="search-wrapper">
      <Title text="חיפוש חבילה" />
      <OrdersSearchFilterer
        onSearchFilterChanged={onSearchFilterChanged}
        filterEnterAndSubmit={filterEnterAndSubmit}
        searchOrdersClick={searchOrdersClick}
      />

      {!orderList || orderList.length < 1 ? (
        <div>אין רשומות</div>
      ) : (
        <>
          <div>
            <div className="page-step-select-wrapper">
              <div className="page-step-label">הצג</div>
              <Select
                className="page-step-select"
                value={pageSize}
                onChange={(e) => onPageSizeClick(e)}
                options={[50, 100, 200].map((size) => ({
                  label: size,
                  value: size,
                }))}
              />
            </div>
          </div>
          <div>
            {ordersStatusesArr.length > 0 && (
              <OrdersList
                onClick={onClickSetOrderToUpdate}
                onRelocateClick={onRelocateClick}
                orderList={orderList}
                isLoadingSearch={isLoadingSearch}
              />
            )}
          </div>
          <PaginationNext
            pageStep={currentSearchedPageStep}
            page={currentSearchedPage}
            onClick={onPageClick}
            currentResponseCount={orderList.length}
          />
        </>
      )}
    </div>
  )
}

export default OrdersSearchScreen

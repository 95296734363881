import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  setOrdersSearch,
  fetchStationsByRole,
  fetchOrderStatuses,
  fetchOrderTypes,
  clearOrdersSearch,
} from "../../../actions"

import { InputDatePicker, SelectAutocomplete, Button } from "../../common"
import InputFiled from "../InputField"
import { isValidField } from "../../../utils/FieldsValidation"
import NotificationModal from "../NotificationModal"
import { fetchIfEmpty } from "../../../utils"
import "./style.scss"

const OrdersSearchFilterer = ({
  onSearchFilterChanged,
  searchOrdersClick,
  filterEnterAndSubmit,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const authenticated = useSelector((state) => state.users.authenticated)

  const searchData = useSelector((state) => state.searchOrders.searchData)
  const isLoadingSearch = useSelector(
    (state) => state.searchOrders.isLoadingSearch
  )
  const ordersStatuses_SelectList = useSelector(
    (state) => state.orderStatuses.ordersStatuses_SelectList
  )
  const orderTypes_SelectList = useSelector(
    (state) => state.orderTypes.orderTypes_SelectList
  )

  const selectedDecathlonStation = useSelector(
    (state) => state.station.selectedDecathlonStation
  )
  const stationsItemSelectList = useSelector(
    (state) => state.station.stationsItemSelectList
  )
  const stationSelectList = useSelector((state) => {
    if (selectedDecathlonStation && stationsItemSelectList) {
      var result = stationsItemSelectList.find((obj) => {
        return obj.value === selectedDecathlonStation
      })
      if (result) {
        return [result]
      }
    }

    if (
      stationsItemSelectList &&
      stationsItemSelectList.length > 0 &&
      stationsItemSelectList[0].value !== 0
    ) {
      const defaultAllStation = {
        value: 0,
        stationId: "",
        label: "כל העמדות",
      }

      return [defaultAllStation, ...stationsItemSelectList]
    }
    return stationsItemSelectList
  })

  const INIT_STATE = {
    defaultStatus: -1,
    defaultStation: "",
    defaultOrderType: 0,
    searchData: {
      fromDate: null,
      toDate: null,
      orderNumber: "",
      packageNumber: "",
      orderStatus: -1,
      mobilePhone: "",
      firstName: "",
      lastName: "",
    },
    validField: {
      orderNumber: {
        type: "orderNumber",
        isRequired: false,
        isChecked: false,
        compareMinValue: 1,
        compareMaxValue: 8,
        isValid: false,
      },
      packageNumber: {
        type: "packageNumber",
        isRequired: false,
        isChecked: false,
        compareMinValue: 3,
        compareMaxValue: 90,
        isValid: false,
      },
      mobilePhone: {
        type: "mobile",
        isRequired: false,
        isChecked: false,
        isValid: false,
      },
      firstName: {
        type: "name",
        isRequired: false,
        isChecked: false,
        compareMinValue: 2,
        compareMaxValue: 30,
        isValid: false,
      },
      lastName: {
        type: "name",
        isRequired: false,
        isChecked: false,
        compareMinValue: 2,
        compareMaxValue: 30,
        isValid: false,
      },
    },
  }

  const [state, setState] = useState(INIT_STATE)
  const [showDateCantBeNull, setShowDateCantBeNull] = useState(false)

  useEffect(() => {
    if (!authenticated) {
      navigate("/")
      return
    }

    fetchIfEmpty(stationsItemSelectList, () => dispatch(fetchStationsByRole()))

    const isEmptySearch =
      JSON.stringify(searchData) === JSON.stringify(INIT_STATE.searchData)

    if (isEmptySearch) {
      let newSearchData = {
        ...searchData,
        fromDate: getDefaultFromDate(),
        toDate: getDefaultToDate(),
      }

      if (selectedDecathlonStation) {
        newSearchData = {
          ...newSearchData,
          station: selectedDecathlonStation,
        }
      }

      dispatch(setOrdersSearch(newSearchData))
    }

    setDefaultsAndSearch(searchData)
    searchClick()
    return () => {
      dispatch(clearOrdersSearch())
    }
  }, [])

  useEffect(() => {
    const isEmptySearch =
      JSON.stringify(searchData) === JSON.stringify(INIT_STATE.searchData)
    const isDataChanged =
      JSON.stringify(searchData) !== JSON.stringify(state.searchData)

    if (isEmptySearch) {
      const newSearchData = {
        ...searchData,
        fromDate: getDefaultFromDate(),
        toDate: getDefaultToDate(),
      }
      setDefaultsAndSearch(newSearchData)

      dispatch(setOrdersSearch(newSearchData))
    } else if (isDataChanged) {
      if (selectedDecathlonStation && searchData.station === "") {
        searchData.station = selectedDecathlonStation
      }
      setDefaultsAndSearch(searchData)
    }
  }, [searchData])

  const getDefaultFromDate = () => {
    let defaultDate = new Date()
    let newMonth = defaultDate.getMonth() - 3

    if (newMonth <= 0) {
      newMonth = newMonth <= 0 ? newMonth + 12 : newMonth
      defaultDate.setMonth(newMonth)
      defaultDate.setFullYear(defaultDate.getFullYear() - 1)
    } else {
      defaultDate.setMonth(newMonth)
    }

    return formatDateForPicker(defaultDate)
  }

  const getDefaultToDate = () => {
    return formatDateForPicker(new Date())
  }

  const formatDateForPicker = (date) => {
    let day = date.getDate()
    day = day < 10 ? "0" + day : day

    let month = date.getMonth() + 1
    month = month < 10 ? "0" + month : month

    return `${date.getFullYear()}-${month}-${day}`
  }

  const setDefaultsAndSearch = (searchData) => {
    if (searchData.fromDate == null) {
      searchData.fromDate = getDefaultFromDate()
    }

    if (searchData.toDate == null) {
      searchData.toDate = getDefaultToDate()
    }

    let defaultStatus = {
      label: "כל הסטטוסים",
      value: -1,
    }
    if (ordersStatuses_SelectList?.length > 0) {
      defaultStatus = ordersStatuses_SelectList.find(
        (s) => s.value === searchData.orderStatus
      )
    } else {
      fetchIfEmpty(ordersStatuses_SelectList, () =>
        dispatch(fetchOrderStatuses())
      )
    }

    let defaultOrderType = {
      label: "כל סוגים",
      value: 0,
    }
    if (orderTypes_SelectList?.length > 0) {
      defaultOrderType = orderTypes_SelectList.find(
        (s) => s.value === searchData.orderType
      )
    } else {
      fetchIfEmpty(orderTypes_SelectList, () => dispatch(fetchOrderTypes()))
    }

    if (selectedDecathlonStation) {
      searchData.station = selectedDecathlonStation
    }

    let defaultStation = {
      value: 0,
      stationId: "",
      label: "כל העמדות",
    }

    if (stationSelectList?.length > 0) {
      defaultStation = stationSelectList[0]
      if (searchData?.station?.length > 0) {
        defaultStation = stationSelectList.find(
          (s) => s.stationNumber === searchData.station
        )
      } else {
        fetchIfEmpty(stationSelectList, () => dispatch(fetchStationsByRole()))
      }
    }

    setState((prev) => ({
      ...prev,
      searchData,
      defaultOrderType,
      defaultStatus,
      defaultStation,
    }))
  }

  const validateInputValue = (inputName, value) => {
    setState((prev) => {
      const newValidField = { ...prev.validField }
      const field = newValidField[inputName]

      if (!field) return prev

      field.isChecked = true
      field.isValid = isValidField(
        value,
        field.type,
        field.isRequired,
        field.compareMinValue,
        field.compareMaxValue
      )

      return {
        ...prev,
        validField: newValidField,
      }
    })
  }

  const updateActiveType = (item) => {
    setState((prev) => {
      const newSearchData = {
        ...prev.searchData,
        orderType: Number(item.value),
        page: 1,
      }
      onSearchFilterChanged(newSearchData)
      return {
        ...prev,
        searchData: newSearchData,
        defaultOrderType: item,
      }
    })
  }

  const updateActiveStatus = (item) => {
    if (item?.value == undefined) return

    setState((prev) => {
      const newSearchData = {
        ...prev.searchData,
        orderStatus: item.value,
        page: 1,
      }
      onSearchFilterChanged(newSearchData)
      return {
        ...prev,
        searchData: newSearchData,
        defaultStatus: item,
      }
    })
  }

  const updateActiveStation = (item) => {
    setState((prev) => {
      const newSearchData = {
        ...prev.searchData,
        station: item.value,
        page: 1,
      }
      onSearchFilterChanged(newSearchData)
      return {
        ...prev,
        searchData: newSearchData,
        defaultStation: item,
      }
    })
  }

  const updateActiveField = (fieldName, value) => {
    validateInputValue(fieldName, value)
    setState((prev) => {
      const newSearchData = {
        ...prev.searchData,
        [fieldName]: value,
        page: 1,
      }
      onSearchFilterChanged(newSearchData)
      return {
        ...prev,
        searchData: newSearchData,
      }
    })
  }

  const handleDateChange = (inputName, event) => {
    if (inputName === "fromDate" && event.target.value === "") {
      setShowDateCantBeNull(true)
      return
    }

    setState((prev) => {
      const newSearchData = {
        ...prev.searchData,
        [inputName]: event.target.value,
        page: 1,
      }
      onSearchFilterChanged(newSearchData)
      return {
        ...prev,
        searchData: newSearchData,
      }
    })
  }

  const searchClick = () => {
    setState((prev) => {
      const newSearchData = {
        ...prev.searchData,
        page: 1,
      }
      onSearchFilterChanged(newSearchData)
      return {
        ...prev,
        searchData: newSearchData,
      }
    })
    searchOrdersClick()
  }

  // const cleanSearch = () => {
  //   dispatch(clearOrdersSearch())
  //   setState({
  //     ...INIT_STATE,
  //     search: {
  //       ...INIT_STATE.search,
  //       pageStep: searchData.pageStep,
  //     },
  //   })
  // }

  const { searchData: localSearchData, validField } = state
  const {
    fromDate,
    toDate,
    orderNumber,
    packageNumber,
    mobilePhone,
    firstName,
    lastName,
  } = localSearchData

  return (
    <>
      <div className="search-orders-filterer">
        <div className="line-wrapper">
          <InputDatePicker
            name="fromDate"
            date={fromDate}
            onChange={handleDateChange}
            placeholder="מתאריך"
          />

          <InputDatePicker
            name="toDate"
            date={toDate}
            onChange={handleDateChange}
            placeholder="עד תאריך"
          />

          <InputFiled
            show
            onChange={(e) => updateActiveField("orderNumber", e.target.value)}
            extraClass="search-input"
            placeholder="חיפוש לפי מספר הזמנה"
            value={orderNumber}
            icon="magnifying_glass"
            isFocused={true}
            showValidation={validField["orderNumber"].isChecked}
            showValidationOk={validField["orderNumber"].isValid}
            showValidationError={!validField["orderNumber"].isValid}
            validationErrorMessage="המספר אמור להכיל עד 8 ספרות"
            maxLength={8}
          />

          <InputFiled
            show
            onChange={(e) => updateActiveField("packageNumber", e.target.value)}
            extraClass="search-input"
            placeholder="חיפוש לפי מספר חבילה"
            value={packageNumber}
            alt="ניתן לחפש הזמנה לפי מספר חבילה."
            icon="magnifying_glass"
            showValidation={validField["packageNumber"].isChecked}
            showValidationOk={validField["packageNumber"].isValid}
            showValidationError={!validField["packageNumber"].isValid}
            validationErrorMessage=" לפחות 3 מספרים או אותיות באנגלית"
            maxLength={30}
          />

          <InputFiled
            show
            onChange={(e) => updateActiveField("mobilePhone", e.target.value)}
            extraClass="search-input"
            placeholder="חיפוש לפי נייד"
            value={mobilePhone}
            alt="ניתן לחפש הזמנה לפי נייד לקוח בהזמנה ."
            icon="magnifying_glass"
            showValidation={validField["mobilePhone"].isChecked}
            showValidationOk={validField["mobilePhone"].isValid}
            showValidationError={!validField["mobilePhone"].isValid}
            validationErrorMessage="המספר אמור להכיל 10 ספרות"
            maxLength={20}
          />
        </div>

        <div className="line-wrapper">
          <InputFiled
            show
            onChange={(e) => updateActiveField("firstName", e.target.value)}
            extraClass="search-input"
            placeholder="חיפוש לפי שם פרטי"
            value={firstName}
            alt="ניתן לחפש הזמנה לפי שם פרטי."
            icon="magnifying_glass"
            showValidation={validField["firstName"].isChecked}
            showValidationOk={validField["firstName"].isValid}
            showValidationError={!validField["firstName"].isValid}
            validationErrorMessage="שם אמור להכיל אותיות "
            maxLength={30}
          />

          <InputFiled
            show
            onChange={(e) => updateActiveField("lastName", e.target.value)}
            onKeyPress={filterEnterAndSubmit}
            extraClass="search-input"
            placeholder="חיפוש לפי שם משפחה"
            value={lastName}
            alt="ניתן לחפש הזמנה לפי שם משפחה של לקוח."
            icon="magnifying_glass"
            showValidation={validField["lastName"].isChecked}
            showValidationOk={validField["lastName"].isValid}
            showValidationError={!validField["lastName"].isValid}
            validationErrorMessage="שם אמור להכיל אותיות "
            maxLength={30}
          />

          <SelectAutocomplete
            placeholder="סטטוס הזמנה"
            items={ordersStatuses_SelectList}
            value={state.defaultStatus}
            onChangeHandler={updateActiveStatus}
          />

          <SelectAutocomplete
            placeholder="סוג הזמנה"
            items={orderTypes_SelectList}
            value={state.defaultOrderType}
            onChangeHandler={updateActiveType}
          />

          <SelectAutocomplete
            placeholder="עמדה"
            items={stationSelectList}
            value={state.defaultStation}
            onChangeHandler={updateActiveStation}
          />

          <div className="search-btn-wrapper">
            <Button
              extraClass={`${isLoadingSearch ? "disabled" : "search-btn"}`}
              onClick={(e) => searchClick()}
              tabIndex="0"
              disabled={isLoadingSearch}
            >
              חפש
            </Button>
          </div>
        </div>
      </div>
      <NotificationModal
        type={"warning"}
        title={"התאריך שמנסים לבחור לא תקין"}
        show={showDateCantBeNull}
        text={" אנא בחר תאריך תקין. לא נתן לרוקן את השדה."}
        onOkClick={() => setShowDateCantBeNull(false)}
      />
    </>
  )
}

export default OrdersSearchFilterer

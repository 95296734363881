import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import TableContainer from "../../../common/TableContainer"
import { SelectColumnFilter } from "../../../../filters/SelectColumnFilter"
import { Container } from "reactstrap"
import { getFormatedDate } from "../../../../utils/FormatedDate"
import { setEmtyIfNull } from "../../../../utils/getIfEmpty"
import edit_blue from "../../../../assets/svg/edit_blue.svg"
import { createColumnHelper } from "@tanstack/react-table"
import "./style.scss"

const OrdersList = ({ onClick }) => {
  const [filterValue, setFilterValue] = useState("")

  const {
    orderList,
    ordersStatusesArr,
    orderTypesArr,
    userData,
    selectedDecathlonStation,
  } = useSelector((state) => ({
    orderList: state.searchOrders.orderList,
    ordersStatusesArr: state.orderStatuses.ordersStatusesArr,
    orderTypesArr: state.orderTypes.orderTypesArr,
    userData: state.users.userData,
    selectedDecathlonStation: state.station.selectedDecathlonStation,
  }))

  const formateOrder = (order) => {
    const {
      id,
      orderNumber,
      packageNumber,
      externalOrderNumber,
      externalOrderNumber2,
      mobilePhone,
      firstName,
      lastName,
      orderStatus,
      orderType,
      orderDate,
      branchDisplayName,
      createdByName,
      inLockerExtentionDate,
    } = order

    return {
      id,
      branchDisplayName,
      orderNumber,
      packageNumber,
      externalOrderNumber: setexternalOrderNumber(
        externalOrderNumber,
        externalOrderNumber2
      ),
      mobilePhone: setEmtyIfNull(mobilePhone),
      firstName: setEmtyIfNull(firstName),
      lastName: setEmtyIfNull(lastName),
      createdByName,
      orderStatus: ordersStatusesArr[orderStatus],
      orderType: orderTypesArr[orderType],
      orderDate: getFormatedDate(orderDate),
      inLockerExtentionDate: getFormatedDate(inLockerExtentionDate),
    }
  }

  const setexternalOrderNumber = (
    externalOrderNumber,
    externalOrderNumber2
  ) => {
    if (externalOrderNumber != null) {
      return externalOrderNumber
    } else if (externalOrderNumber2 != null) {
      return externalOrderNumber2
    }
    return ""
  }

  const ordersByDecathloneStation = useMemo(() => {
    if (orderList) {
      let filteredOrders
      if (selectedDecathlonStation) {
        filteredOrders = orderList.filter((order) => {
          return order.stationNumber === selectedDecathlonStation
        })
      } else {
        filteredOrders = orderList
      }

      return filteredOrders
        ? filteredOrders.map((order) => formateOrder(order))
        : []
    } else {
      return [filterValue]
    }
  }, [orderList, selectedDecathlonStation, filterValue])

  const data = ordersByDecathloneStation

  const columnHelper = createColumnHelper()

  const columns = useMemo(() => {
    const result = [
      columnHelper.accessor("orderDate", {
        header: "תאריך",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("packageNumber", {
        header: "מספר חבילה",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("mobilePhone", {
        header: "טלפון",
        cell: (info) => info.getValue() || "N/A",
      }),
      columnHelper.accessor("firstName", {
        header: "שם פרטי",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("lastName", {
        header: "שם משפחה",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("orderStatus", {
        header: "סטטוס",
        cell: (info) => info.getValue(),
        filterFn: "includes",
      }),
      columnHelper.accessor("createdByName", {
        header: "נוצר על ידי",
        cell: (info) => info.getValue(),
        filterFn: "includes",
      }),
      columnHelper.accessor("branchDisplayName", {
        header: "שם העמדה",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("inLockerExtentionDate", {
        header: "תאריך הארכה",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("orderType", {
        header: "סוג הזמנה",
        cell: (info) => info.getValue(),
      }),
    ]

    if (userData && userData.role !== "Decathlon") {
      result.splice(
        2,
        0,
        columnHelper.accessor("orderNumber", {
          header: "מספר הזמנה",
          cell: (info) => info.getValue(),
        })
      )
    }

    if (userData?.role !== "Decathlon" && userData.role !== "TAU") {
      result.push(
        columnHelper.accessor("externalOrderNumber", {
          header: "שטר מטען",
          cell: (props) => (
            <div
              className="externalOrderNumber"
              title={props.cell.row.original.externalOrderNumber}
            >
              {props.cell.row.original.externalOrderNumber}
            </div>
          ),
        })
      )
    }

    if (userData) {
      result.unshift(
        columnHelper.display({
          id: "edit",
          header: () => null,
          cell: (props) => (
            <div className="order-edit">
              <img
                alt="edit_button"
                onClick={() => onClick(props.cell.row.original.orderNumber)}
                src={edit_blue}
              />
            </div>
          ),
        })
      )
    }

    return result
  }, [userData, ordersStatusesArr, orderTypesArr, onClick])

  return (
    <div className="order-list-wrapper">
      <Container className="order-list">
        <TableContainer
          columns={columns}
          data={data}
          filterValue={filterValue}
          resetFilterValue={() => setFilterValue("")}
          showTablePagination={false}
          showExportToExcel={true}
        />
      </Container>
    </div>
  )
}

export default OrdersList
